import React from 'react'
import { Navigate } from 'react-router-dom'
import useToken from "./componenets/useToken";
export default function Protected({ children }: UserProps) {
    const { token } = useToken();
    if (token !== "ILov3R@mmy") {
        return (<Navigate to="/login" replace />)
    }
    return (
        <div>
            {children}
        </div>
    );
}

export interface UserProps {
    children: React.ReactNode;
}