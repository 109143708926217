import { useState } from "react";

export default function useToken(){
    function getToken() {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString!);
        console.log(userToken)
        return userToken
    }

    const [token, setToken] = useState(getToken());

    function saveToken(userToken: any) {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    }

    return {
        setToken: saveToken,
        token
    }

}