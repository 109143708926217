import {Button, Card, Col, Container, FloatingLabel, Form, Image, Row} from "react-bootstrap";
import PropTypes, {InferProps} from "prop-types";
import {Navigate, useNavigate} from "react-router-dom";
import React from "react";
import useToken from "../componenets/useToken";

export default function Login({setToken}: InferProps<typeof Login.propTypes>) {
    const { token } = useToken();
    const navigate = useNavigate();
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setToken(e.currentTarget.elements[0].value);
        navigate("/");
    }

    if (token === "ILov3R@mmy") {
        return (<Navigate to="/" replace />)
    }


    return (
        <section className="h-100">
            <Container className="py-5 h-100" fluid>
                <Row className="d-flex justify-content-center align-items-center h-100">
                    <Col xl={10}>
                        <Card className="top-sm-25 rounded-3 shadow">
                            <Row>
                                <Col lg={6}>
                                    <Card.Body>
                                        <div className="text-center">
                                            <Image src="https://ramsoc.co.uk/img/UoNSport_Rambling.png"
                                                   className="mb-2 w-50"/>
                                            <h4 className="nt-1 mb-4 pb-1">Member Dashboard</h4>
                                        </div>
                                        <Form onSubmit={handleSubmit}>
                                            <div className="mb-4">
                                                <FloatingLabel controlId="floatingPassword" label="Access Code">
                                                    <Form.Control type="password" placeholder="Access Code"/>
                                                </FloatingLabel>
                                            </div>
                                            <div className="text-center pt-1 mb-5 pb-1 d-grid gap-2">
                                                <Button variant="primary" type="submit"
                                                        className="btn-uongreen btn-block">
                                                    Sign in
                                                </Button>
                                            </div>
                                        </Form>
                                        <div className="d-flex align-items-center justify-content-center pb-4">
                                            <p className="mb-0 me-2">Want to get access?</p>
                                            <Button variant="outline-primary" type="submit"
                                                    className="btn-uongreen-outline">
                                                Purchase Membership
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Col>
                                <Col lg={6} className="font-uon text-white">
                                    <div className="login-color h-100 w-100">
                                        <div className="px-3 py-4 p-md-5 mx-md-4 ">
                                            <div className="mb-5">
                                                <h4>How to access?</h4>
                                                <p>The access code is attached to every weekly newsletter. Otherwise
                                                    contact committee</p>
                                            </div>
                                            <div>
                                                <h4>Membership Benefits</h4>
                                                <ul>
                                                    <li>Exclusive discount at outdoor retailers</li>
                                                    <li>Exclusive events and socials</li>
                                                    <li>Reduced trip fees</li>
                                                    <li>Weekly Newsletter</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
}

