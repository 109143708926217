import * as React from "react";
import {Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import Protected from "./Protected";
import Login from "./pages/Login";
import Home from "./pages/Home";
import useToken from "./componenets/useToken";
import Footer from "./componenets/footer";
import {Container} from "react-bootstrap";

export default function App() {
    const { setToken } = useToken();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <Protected>
                        <Container className="min-vh-100">
                            <Home/>
                            <Footer/>
                        </Container>
                    </Protected>
                }/>
                <Route path="/login" element={
                    <div className="min-vh-100 login-background">
                        <div className="container">
                            <Login setToken={setToken}/>
                        </div>
                    </div>
                }/>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    )
}
