import * as React from "react";

export default function VoucherGroup({children}: UserProps) {
    return (
        <div>
            {children}
        </div>
    );
}

export interface UserProps {
    children: React.ReactNode;
}