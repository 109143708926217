import VoucherGroup from "../componenets/voucherGroup";
import Voucher from "../componenets/voucher";
import cotsworld from "../img/cotsworld.png";
import magic from "../img/magic.avif";
import tresspass from "../img/tresspass.png";
import mountainwairehouse from "../img/mountainwarehouse.png";

export default function Home() {
    return (
        <div className="align-content-center text-center">
            <h1 className="text-uon-green font-uon fw-bold display-1">Members Dashboard</h1>
            <p>Thank you for supporting us!</p>
            <p>This site is currently in early access and several features are currently missing.</p>
            <h2 className="text-uon-green font-uon fw-bold display-3">Discount</h2>
            <div>
                <VoucherGroup>
                    <Voucher title="Tresspass" amount={20} code="2XDOJZOZ87TE" imgLocation={tresspass} description="Trespass branded outdoor wear"/>
                    <Voucher title="Montane" amount={20} code="UONRH20" imgLocation={mountainwairehouse} description="Montane branded outdoor wear"/>
                    <Voucher title="Mountain Warehouse" amount={15} code="UONRHC" imgLocation={mountainwairehouse} description="Mountain Warehouse branded outdoor wear"/>
                    <Voucher title="Magic Mountain" amount={15} code="MagicRamsoc" imgLocation={magic} description="Mountain Equipment Outlet"/>
                    <Voucher title="Cotsworld Outdoor" amount={10} code="AF-STUDENT-AF" imgLocation={cotsworld} description="Outdoor clothing & Equipment"/>
                </VoucherGroup>
            </div>
        </div>

    );
}
