import * as React from "react";
import {Button, Col, Card, Form, InputGroup, Row} from "react-bootstrap";

export default function Voucher({title, imgLocation, code, amount, description}: UserProps) {
    return (<div className="m-3 pb-2">
            <Card className="rounded-3 shadow p-3">
                <Row className="align-content-center">
                    {/*<Col md={3} xs={12}>*/}
                    {/*    <div className="deal-logo ">*/}
                    {/*        <Image src={imgLocation} fluid className="object-fit-fill"/>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col md={4} xs={9} className="align-self-center">
                        <h4>{title}</h4>
                    </Col>
                    <Col md={1} xs={3} className="align-self-center">
                        <h4>{amount}%</h4>
                    </Col>
                    <Col md={3} xs={12} className="align-self-center">
                        <p>{description}</p>
                    </Col>
                    <Col md={4} xs={12} className="align-self-center">
                        <InputGroup className="mb-3">
                            <Form.Control
                                aria-label="promo code"
                                aria-describedby="basic-addon2"
                                defaultValue={code}
                                readOnly
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => {navigator.clipboard.writeText(code)}}>
                                Copy
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Card>
        </div>);
}


export interface UserProps {
    title: string;
    imgLocation: string;
    code: string;
    amount: number;
    description: string;
}